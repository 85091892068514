import React, { useContext, useRef, useState } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { themeContext } from "../../Context";

const Contact = () => {
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    const form = useRef();

    const[done, setDone] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_lakep9l', 'template_vxskldg', form.current, 'lYFkhlJxQ4m2uVxN4')
      .then((result) => {
          setDone(true)
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };


  return (
    <div className='contact-form'>
       <div className="w-left">
           <div className="awesome">
               <span style={{color: darkMode?'white': ''}}>Get in touch</span>
               <span>Contact me</span>
               <div className="blur s-blur1"
               style={{ background:'#ABF1FF94' }}
               ></div>
           </div>
       </div>

       <div className="c-right">
           <form ref={form} onSubmit={sendEmail}>
               <input type="text" name="from_name" id='' className='user' placeholder='Name' />
               <input type="email" name="from_email" className='user' placeholder='Email' />
               <textarea name="message" className='user' placeholder='Message'></textarea>
               <input type="submit" value='Send' className='button' />
               <span>{done && 'Thanks for contacting me!'}</span>
               <div className="blur v-blur1"
               style={{ background:'var(--purple)' }}
               ></div>
           </form>
       </div>
    </div>
  )
}

export default Contact
