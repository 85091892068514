import React, { useContext } from 'react';
import './Portfolio.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import Sidebar from '../../img/sidebar.png'
import Ecommerce from '../../img/ecommerce.png'
import Hoc from '../../img/hoc.png'
import MusicApp from '../../img/musicapp.png'
import { themeContext } from "../../Context";
import 'swiper/css'

const Portfolio = () => {
  SwiperCore.use([Autoplay])
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className='portfolio' id='Portfolio'>
      {/*heading */}
      <span style={{color: darkMode?'white': ''}}>Recent Projects</span>
      <span>Portfolio</span>

      {/*swiper */}

      <Swiper
      autoplay={{ delay: 2000 }} 
      spaceBetween={30}
      slidesPerView={3}
      grabCursor={true}
      className='portfolio-slider'
      >
          <SwiperSlide>
              <img src={Sidebar} alt="" />
          </SwiperSlide>
          <SwiperSlide>
              <img src={Ecommerce} alt="" />
          </SwiperSlide>
          <SwiperSlide>
              <img src={Hoc} alt="" />
          </SwiperSlide>
          <SwiperSlide>
              <img src={MusicApp} alt="" />
          </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Portfolio
